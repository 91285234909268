import React from "react";
import "../../_static/css/component/promo/emberBanner.scss";
import videomp4 from "../../_static/videos/embers.mp4";
import sitemap from "../../App/sitemap.json";

import logo from "../../_static/images/kickstarter.png";
import "../../_static/css/component/promo/kickstarterLogo.scss";
import {Link} from "react-router-dom";
const KickstarterLogo = () => {
    return <div className="bg-logo">
        <img src={logo} className="logo-dark" alt="Bunguin Games Logo"/>
    </div>;
}

const KickstarterBanner = () => {
    if (!sitemap.home.emberBanner) {
        return (<></>);
    }
    return (
        <div className="videoContainer">
            <Link to={"https://www.kickstarter.com/projects/bunguingames/headlice-a-reverse-horror-game?ref=9ycpgo"}><KickstarterLogo/></Link>
        </div>
    );
};

export default KickstarterBanner;