import React from "react";
import KickstarterBanner from "../component/promo/KickstarterBanner";
// import EmberBanner from "../component/promo/EmberBanner";
import Tagline from "../component/promo/Tagline";
import Headlice from "../component/promo/Headlice";
import ColdSalvage from "../component/promo/ColdSalvage";
import TeamBanner from "../component/content/TeamBanner";
import Discord from "../component/content/Discord";
import Shop from "../component/content/Shop";
import News from "../component/content/News";

function IndexView() {
    return (
        <div>
            <KickstarterBanner/>
            <Tagline/>
            <Headlice/>
            <ColdSalvage/>
            <TeamBanner/>
            <Discord/>
            <News/>
            <Shop/>
        </div>
    );
}

export default IndexView;
